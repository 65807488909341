import React from "react";
import { List, ListItemButton} from "@mui/material";
import RequestSection from "./sections/RequestSection";
import  {useNavigate} from "react-router-dom";
import EventSection from "./sections/EventSection";
import FilesSections from "./sections/FilesSections";

export default function AdminPage(props) {
    document.title = "Karaoke in Movimento - Amministrazione"

    const navigate = useNavigate()

    const [currentLocation, setCurrentLocation] = React.useState("events")

    const goTo = (loc) =>{
        setCurrentLocation(loc)
    }

    return <div style={{display: "flex", flexDirection: "row"}}>
        <div className="navigation">
            <List>
                <ListItemButton onClick={props.handleLogOut}>Esci</ListItemButton>
                <ListItemButton onClick={()=>goTo("events")}>Eventi</ListItemButton>
                <ListItemButton onClick={()=>goTo("request")}>Richieste</ListItemButton>
                <ListItemButton onClick={()=>goTo("files")}>File</ListItemButton>
            </List>
        </div>
        <div className="container-page-admin">
            {currentLocation === "request" && <RequestSection {...props} />}
            {currentLocation === "events" && <EventSection {...props} />}
            {currentLocation === "files" && <FilesSections {...props} />}
        </div>
    </div>

}