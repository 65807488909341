import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: {
            logged: false
        }
    },
    reducers: {
        setUserData: (state, action)=>{
            state.data = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserData } = userSlice.actions

export default userSlice.reducer