import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        data: {
            logged: false
        }
    },
    reducers: {
        setAdminData: (state, action)=>{
            state.data = action.payload
        },
        logout: (state, action) =>{
            state.data = {logged: false}
        }
    },
})

// Action creators are generated for each case reducer function
export const { setAdminData, logout } = adminSlice.actions

export default adminSlice.reducer