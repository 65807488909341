import EventSelector from "../../components/EventSelector";
import {LinearProgress} from "@mui/material";
import RequestList from "../components/RequestList";
import React from "react";
import utils from "../../components/utils";

function RequestSection(props) {

    const [eventid, setEventId] = React.useState()
    const [content, setContent] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false)
    const [reload, setReload] = React.useState(true)

    const update = () => {
        setReload(r => !r)
    }

    const onClose = (event_id) => {
        utils.apiPost('request/update', {id: event_id}, {authorization: "Bearer " + props.admin.token})
            .then(() => {
                update()
            })
            .catch(err => {
                props.handleError(err.request.status)
            })
    }

    const loadList = () => {
        if (eventid) {
            setIsLoading(true)
            utils.apiGet(`request/list?event_id=${eventid}`, {authorization: "Bearer " + props.admin.token})
                .then((result) => {
                    setIsLoading(false)
                    setContent(result.content)
                }).catch(err => {
                setIsLoading(false)
                props.handleError(err.request.status)
            })
        }
    }


    React.useEffect(() => {
        const interval = setInterval(() => {
            loadList()
        }, 30000);
        return () => clearInterval(interval);
    }, [eventid])

    React.useEffect(() => {
        loadList()
    }, [reload, eventid])


    return <>
        <div className="type-box-100">
            <EventSelector
                variant="filled"
                name="event"
                label="Evento"
                fullWidth
                host={props.admin.host_id}
                value={eventid}
                change={(state) => {
                    setEventId(state.target.value)
                }}/>
        </div>
        {
            isLoading && <LinearProgress/>
        }
        {
            !isLoading && content && (<RequestList content={content} handleClose={onClose}/>)
        }
    </>

}

export default React.memo(RequestSection)