import MainPage from "./pages/main/MainPage";
import LoginPage from "./pages/login/LoginPage";
import FormPage from "./pages/form/FormPage";
import EventPage from "./pages/event/EventPage";
import AdminLoginPage from "./admin/AdminLoginPage";
import AdminPage from "./admin/AdminPage";
import AdminProvider from "./admin/components/AdminProvider";
import {Navigate} from "react-router-dom";

const routes = [
    {
        path: "/",
        key: "/",
        element: <MainPage/>
    },
    {
        path: "/login",
        key: "/login",
        element: <LoginPage/>
    },
    {
        path: "/event/:id",
        key: "/event",
        element: <EventPage/>
    },
    {
        path: "/form",
        key: "/form",
        element: <FormPage/>
    },
    {
        path: "/admin/login",
        key: "/admin/log",
        element: <AdminLoginPage/>
    },
    {
        path: "/admin/page",
        key: "/admin/pag",
        element: <AdminProvider children={(props)=><AdminPage {...props}/>} />
    },
    {
        path: "/admin/*",
        key: "ad/*",
        element: <Navigate to="/admin/login" />
    }
]
export default routes