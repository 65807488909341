import React from "react";
import {logout, setAdminData} from "../../slices/adminSlice";
import {useNavigate} from "react-router-dom";
import useAdminData from "../../hooks/useAdminData";

export default function AdminProvider({children}) {

    const admin = useAdminData()
    const navigate = useNavigate()
    const adminData = JSON.parse(localStorage.getItem("admin"))
    const adminInfo = adminData || admin

    const [load, setLoad ] = React.useState(true)

    const handleError = (status) => {
        if (status === 401) {
            localStorage.removeItem("admin")
            logout()
            navigate("/admin/login")
        }
    }

    const handleLogOut = () => {
        localStorage.removeItem("admin")
        logout()
        navigate("/admin/login")
    }

    React.useEffect(() => {
        if (!admin.logged && !adminData) {
            navigate("/admin/login")
        }
        if (adminData) {
            setAdminData(adminData)
        }
        setLoad(false)
    }, [navigate, admin.logged, adminData])

    const adminProps = {
        handleError,
        handleLogOut,
        admin: {...adminInfo}
    }

    if(load)
        return <></>

    return <>{children(adminProps)}</>

}