import React from "react";
import {useDispatch} from "react-redux";
import {Button, TextField, Typography} from "@mui/material";
import utils from "../components/utils";
import {useNavigate} from "react-router-dom";
import {setAdminData} from "../slices/adminSlice";

export default function AdminLoginPage(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const adminData = JSON.parse(localStorage.getItem("admin"))

    const [model, setModel] = React.useState({})

    React.useEffect(() => {
        if (adminData) {
            if (adminData.logged) {
                setAdminData(adminData)
                navigate("/admin/page")
            }
        }
    }, [adminData, navigate])

    const handleLogin = () => {
        utils.apiPost('admin/login', model)
            .then(r => {
                const loginObj = {
                    logged: true,
                    ...r
                }
                localStorage.setItem("admin", JSON.stringify(loginObj))
                dispatch(setAdminData({
                    logged: true,
                    ...r
                }))
                navigate("/admin/page")
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleChange = (event) => {
        setModel(c => {
            c[event.target.id] = event.target.value
            return c
        })
    }

    return <div>
        <div className="d-flex-center">
            <div className="container-admin">
                <Typography fontFamily="Karla" fontSize={25} fontWeight={600} marginBottom={1}>Login Admin</Typography>
                <div className="type-box">
                    <TextField
                        className="field"
                        variant="filled"
                        id="mail"
                        label="Mail"
                        fullWidth
                        value={model.mail}
                        onBlur={handleChange}
                        size="medium"
                    />
                </div>
                <div className="type-box">
                    <TextField
                        className="field"
                        variant="filled"
                        id="pass"
                        label="Password"
                        fullWidth
                        type="password"
                        value={model.pass}
                        onBlur={handleChange}
                        size="medium"
                    />
                </div>
                <Button className="button-accedi" variant="contained" onClick={handleLogin}>Accedi</Button>

            </div>
        </div>
    </div>

}