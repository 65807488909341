import React from "react";
import utils from "../../../components/utils";
import {LinearProgress, MenuItem, TextField} from "@mui/material";

export default function TypeSelector(props) {

    const [isLoading, setIsLoading] = React.useState(false)
    const [list, setList] = React.useState([])

    React.useEffect(() => {
        setIsLoading(true)
        utils.apiGet('/request/type/list')
            .then(result => {
                setList(result.content)
                setIsLoading(false)
            }).catch(() => {
            setIsLoading(false)
        })
    }, [])

    return <TextField
        id={props.name}
        value={props.value}
        onBlur={props.change}
        select
        {...props}
    >
        {
            isLoading ? <LinearProgress/> :
                list?.map((option) => (
                    <MenuItem key={option.type_id} value={option.type_id}>
                        {option.type_name}
                    </MenuItem>
                ))}
    </TextField>

}