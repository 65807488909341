import { createTheme } from "@mui/material"

const bottomBorder = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '--TextField-brandBorderColor': '#E83A8B',
                        '--TextField-brandBorderHoverColor': '#E83A8B',
                        '--TextField-brandBorderFocusedColor': '##E83A8B',
                        '& label.Mui-focused': {
                            color: 'red',
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        '&:before': {
                            borderBottom: '0px solid #E83A8B',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid #E83A8B',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '0px solid #E83A8B',
                        },
                    },
                },
            }
        }
    })

export default bottomBorder