import React from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography, FormControl
} from "@mui/material";
import utils from "../../components/utils";
import {setUserData} from "../../slices/userSlice";
import {useNavigate} from "react-router-dom";


export default function LoginPage(props) {

    document.title = "Karaoke in Movimento - Accedi"

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [mail, setMail] = React.useState()
    const [username, setUser] = React.useState()
    const [newsletter, setNews] = React.useState(true)
    const [privacy, setPrivacy] = React.useState(false)

    const [disable, setDisable] = React.useState(false)

    const handleLogin = () => {
        setDisable(true)
        const model = {
            mail,
            username,
            newsletter
        }
        utils.apiPost('guest/login', model)
            .then(r => {
                dispatch(setUserData({
                    logged: true,
                    ...r
                }))
                navigate("/form")
            })
            .catch(err => {
                setDisable(false)
                if(err.request.status === 409){
                    alert("Errore di accesso\nQuesto utente esiste già! Se sei tu controlla la mail inserita o l'username")
                }
            })
    }

    return <>
        <div className="d-flex-center">
            <FormControl>
                <div className="container-login">

                    <div className="type-box">
                        <TextField
                            className="field"
                            variant="filled"
                            id="mail"
                            label="mail"
                            fullWidth
                            value={mail}
                            onChange={({target}) => {
                                setMail(target.value)
                            }}
                            size="medium"
                        />
                    </div>
                    <div className="type-box">
                        <TextField
                            className="field"
                            variant="filled"
                            id="username"
                            label="Nome del Cantante"
                            fullWidth
                            value={username}
                            onChange={({target}) => {
                                setUser(target.value)
                            }}
                        />
                    </div>

                    <div className="checkBox">
                        <FormControlLabel
                            control={<Checkbox defaultChecked checked={newsletter} onChange={(state) => {
                                setNews(state.target.checked)
                            }}/>} label={<Typography>Iscriviti alla nostra <span>NEWSLETTER</span></Typography>}/>
                        <FormControlLabel control={<Checkbox checked={privacy} onChange={(state) => {
                            setPrivacy(state.target.checked)
                        }}/>} label={<Typography>Accetta la normativa <span>PRIVACY</span></Typography>}/>
                    </div>
                    <div>
                        <Button className="button-login" variant="contained" disabled={disable || !username || !privacy}
                                onClick={handleLogin}>Login!</Button>
                    </div>
                </div>
            </FormControl>

        </div>
    </>

}