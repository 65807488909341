import React from "react";
import { useParams } from "react-router-dom";
import utils from "../../components/utils";
import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";

export default function EventPage(props) {
    document.title = "Karaoke in Movimento - Evento"

    const location = useParams()

    const [eventInfo, setEventInfo] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        setIsLoading(true)
        utils.apiGet("/events/list?id=" + location.id).then(ris => {
            console.log(ris.content[0])
            setEventInfo(ris?.content[0])
            setIsLoading(false)
        })
    }, [location.id])

    if (isLoading)
        return <></>
    return <>
        <div className="container-page">
            <div className="container-card-in">
                <div className="bg-card-in">
                    <div className="img-card-in">
                        <img src={eventInfo.event_image} alt="locandina"/>
                    </div>
                    <h2 className="title-card-in" fontWeight={900}>{eventInfo.event_title}</h2>
                    <Grid container xs={12} justifyContent={"center"} alignItems="center" flexDirection={"row"}>
                        <Icon icon="mdi:calendar" width={20} />
                        <Typography fontFamily="Karla">{utils.formatTime(eventInfo.event_date, 'ddd DD/MM/yyyy HH:mm')}</Typography>
                    </Grid>
                    <Grid container xs={12} justifyContent={"center"} alignItems="center" flexDirection={"row"}>
                        <Icon icon="mdi:map-marker" width={20} />
                        <Typography fontFamily="Karla">{eventInfo.event_location}</Typography>
                    </Grid>
                    <Grid container alignItems="center" justifyContent={"center"} flexDirection={"row"}>
                        <Typography fontFamily="Karla" fontWeight={600} color={"#E83A8B"}>Host: </Typography>
                        <Typography fontFamily="Karla">{eventInfo.host_name}</Typography>
                    </Grid>
                </div>
            </div>
        </div>



    </>

}