import React from "react";
import utils from "./utils";
import {LinearProgress, MenuItem, TextField} from "@mui/material";

export default function FileSelector(props) {

    const [isLoading, setIsLoading] = React.useState(false)
    const [list, setList] = React.useState([])

    React.useEffect(() => {
        setIsLoading(true)
        utils.apiGet('/files', {authorization: "Bearer " + props.admin.token})
            .then(result => {
                setList(result.content)
                setIsLoading(false)
            }).catch(() => {
                setIsLoading(false)
        })
    }, [])

    return <TextField
        id={props.name}
        value={props.value}
        onChange={props.change}
        select
        {...props}
    >
        {
            isLoading ? <LinearProgress/> :
                list.length === 0 ? <MenuItem disabled>Non ci sono immagini</MenuItem> :
                    list.map((option) => (
                        <MenuItem key={option} value={option}>
                            <img style={{maxHeight: "80px", maxWidth: "80px"}} src={`https://karaoke.movimento.events:3001/files/${option}`}/>
                            {option}
                        </MenuItem>
                    ))}
    </TextField>

}