import React from "react";
import axios from "axios"
import {Provider} from 'react-redux'

import moment from "moment";
import 'moment/locale/it'

import store from "./store";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import routes from "./routes";
import {ThemeProvider, useTheme} from "@mui/material";
import bottomBorder from "./Theme";

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

function App() {

    const outerTheme = useTheme();

    axios.defaults.baseURL = "https://karaoke.movimento.events:3001/"

    moment.locale('it')

    const router = createBrowserRouter(routes)

    return <Provider store={store}>
        <ThemeProvider theme={bottomBorder(outerTheme)}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <RouterProvider router={router}/>
            </LocalizationProvider>
        </ThemeProvider>
    </Provider>
}

export default App;
