import axios from "axios";
import moment from "moment";

async function apiGet(url, headers) {

    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: url,
            headers
        }).then(({data}) => {
            return resolve(data)
        }).catch(err => {
            return reject(err)
        })
    })
}

async function apiPost(url, body, headers) {

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            headers,
            data: {
                ...body, // This is the body part
            }
        }).then(({data}) => {
            return resolve(data)
        }).catch(err => {
            return reject(err)
        })
    })
}

function formatTime(data, format, localize) {
    const momentDate = new moment(data)
    if(localize)
        return momentDate.utc(true).local().format(format)
    return momentDate.format(format)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    apiGet,
    apiPost,
    formatTime
}