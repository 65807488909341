import {Button, Typography} from "@mui/material";
import NextEvents from "../components/NextEvents";
import React from "react";

export default function EventSection(props) {
    const admin = props.admin

    return <>
        <Typography>Gestione eventi</Typography>
        <NextEvents admin={admin} handleLogOut={props.handleLogOut}/>
    </>
}