import React from "react";
import { Grid, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import {useNavigate} from "react-router-dom";
import utils from "../../components/utils";

export default function EventCard(props) {

    const navigate = useNavigate()

    const colors = ["#280024", "#6B0040", "#A00090", "#890e46"]

    const handleClick = () =>{
        navigate(`/event/${props.event_id}`)
    }

    function getRandomInt() {
        return Math.floor(Math.random() * colors.length);
    }

    const event_image = props.event_image ? props.event_image : "/imgs/event_image.avif"

    return <div className="cardElem" style={{ background: colors[getRandomInt()] }}  onClick={handleClick}>
        <div className="imgCardCont">
            <img className="cardImage"
                 alt="locandina"
                src={event_image} />
        </div>
        <div className="cardText">
            <Grid container>
                <Grid item xs={12} marginBottom={2}>
                    <Typography fontFamily="Karla" fontSize={25} fontWeight={600}  variant="title">{props.event_title}</Typography>
                </Grid>
                <Grid container alignItems="center" flexDirection={"row"}>
                <Typography fontFamily="Karla" fontWeight={600}>Host:</Typography>
                <Typography fontFamily="Karla" style={{ padding: "0.4rem" }}>{props.host_name}</Typography>
                </Grid>
                <Grid container xs={12} alignItems="center" flexDirection={"row"}>
                    <Icon icon="mdi:calendar" width={20} />
                    <Typography fontFamily="Karla"
                        style={{ padding: "0.4rem" }}>{utils.formatTime(props.event_date, 'ddd DD/MM/yyyy HH:mm')}</Typography>
                </Grid>

                <Grid container alignItems="center" flexDirection={"row"}>
                    <Icon icon="mdi:map-marker" width={20} /><Typography fontFamily="Karla"
                        style={{ padding: "0.4rem" }}>{props.event_location}</Typography>
                </Grid>
            </Grid>
        </div>
    </div >

}
