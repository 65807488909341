import React from "react";
import {
    Button,
    Grid, IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar, ListItemSecondaryAction,
    ListItemText,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import utils from "../../components/utils";
import {DateTimePicker} from "@mui/x-date-pickers";
import {Icon} from "@iconify/react";
import FileSelector from "../../components/FileSelector";

/*
 Note: la sezione deve essere alta massimo lo schemo e gli elementi essere scorribili all'interno di se stessa
 */
function EventItem(props) {

    const handleDelete = (id) => {
        const headers = {authorization: `Bearer ${props.admin.token}`}
        utils.apiPost("/events/delete", {id}, headers)
            .then(() => {
                props.updateRefresh()
            })
            .catch(err => {
                if (err.response.status === 401)
                    props.handleLogOut()
            })
    }

    return <ListItem style={{background_color: "#303030"}}> {/* Bordi arrotondati */}
        <ListItemAvatar>
            <img alt="Cover Evento" src={props.event_image}
                 style={{height: "80px", width: "80px", objectFit: "scale-down"}}/>
        </ListItemAvatar>
        <Grid container>
            <Grid item xs={12}><ListItemText>{props.event_title}</ListItemText></Grid>
            <Grid item xs={12}
                  sm={6}><ListItemText>{utils.formatTime(props.event_date, 'DD/MM/yyyy HH:mm')}</ListItemText></Grid>
            <Grid item xs={12} sm={6}><ListItemText>{props.event_location}</ListItemText></Grid>
        </Grid>
        <ListItemSecondaryAction>
            <IconButton onClick={() => {
                handleDelete(props.event_id)
            }}>
                <Icon icon="material-symbols:close" width={25} color="#303030"/>
            </IconButton></ListItemSecondaryAction>
    </ListItem>
}

function AddSection(props) {
    const [title, setTitle] = React.useState("")
    const [location, setLocation] = React.useState("")
    const [data, setData] = React.useState()
    const [img_path, setImgPath] = React.useState("")

    const clearModel = () => {
        setTitle("")
        setLocation("")
        setData()
        setImgPath("")
    }

    const handleSubmit = () => {
        const headers = {authorization: `Bearer ${props.admin.token}`}
        const model = {
            title,
            location,
            data,
            img_path: `https://karaoke.movimento.events:3001/files/${img_path}`
        }
        utils.apiPost("/events/add", model, headers)
            .then(() => {
                clearModel()
                props.updateRefresh()
            })
            .catch(err => {
                if (err.response.status === 401)
                    props.handleLogOut()
            })
    }

    return <div style={{padding: '0.4rem'}}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField

                    id="title"
                    label="Titolo"
                    fullWidth
                    required
                    value={title}
                    onChange={({target}) => {
                        setTitle(target.value)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="location"
                    label="Posizione / Localita"
                    fullWidth
                    required
                    value={location}
                    onChange={({target}) => {
                        setLocation(target.value)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <DateTimePicker
                    id="data"
                    label="Data evento"
                    required
                    value={data}
                    onChange={(v) => {
                        setData(v)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FileSelector
                    id="img_path"
                    label="Immagine"
                    fullWidth
                    required
                    value={img_path}
                    admin={props.admin}
                    change={({target}) => {
                        setImgPath(target.value)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button disabled={!title || !data || !location || !img_path} variant="contained" fullWidth
                        onClick={handleSubmit}>Conferma</Button>
            </Grid>
        </Grid>
    </div>
}

export default function NextEvents(props) {

    const [events, setEvents] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false);
    const [reload, setReload] = React.useState(true)

    const updateRefesh = () => {
        setReload(r => {
            return !r
        })
    }

    React.useEffect(() => {
        setIsLoading(true)
        utils.apiGet('events/admin', {authorization: "Bearer " + props.admin.token})
            .then(res => {
                setEvents(res.content)
                setIsLoading(false)
            })
            .catch(err => {
                if (err.response.status === 401)
                    props.handleLogOut()
                setIsLoading(false)
            })
    }, [props, props.admin.token, reload])

    return <>
        <div>
            {isLoading ? (<LinearProgress/>) :
                <Grid container>
                    <Grid item xs={12} sm={8} style={{padding: "0.2rem"}}>
                        <Paper style={{height: "80vh", maxHeight: "80vh"}}>
                            <div style={{padding: "0.2rem"}}>
                                <Typography variant="title">I tuoi eventi</Typography>
                                <Typography variant={"subtitle1"}>Lista dei tuoi prossimi eventi</Typography>
                            </div>
                            <>
                                {events.length === 0 ? <Typography>Non ci sono ancora eventi</Typography> :
                                    <List>
                                        {events?.map((e, k) => (
                                            <EventItem key={k} {...e} {...props} updateRefresh={updateRefesh}/>
                                        ))}
                                    </List>
                                }
                            </>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{padding: "0.2rem"}}>
                        {/** NEW ITEM */}
                        <Paper style={{height: "80vh", maxHeight: "80vh"}}>
                            <div style={{padding: "0.2rem"}}>
                                <Typography variant="title">Nuovo evento</Typography>
                                <Typography variant={"subtitle1"}>Aggiungi un nuovo evento</Typography>
                            </div>
                            <AddSection {...props} updateRefresh={updateRefesh}/>
                        </Paper>
                    </Grid>
                </Grid>
            }
        </div>
    </>

}