import React from "react";
import {IconButton, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import utils from "../../components/utils";
import {Icon} from "@iconify/react";

export default function RequestItem(props) {

    return <>

        <ListItem>
            <div className="d-flex-wrap req-box">
                <ListItemText className="req-item">{utils.formatTime(props.reqs_date, 'HH:mm:ss')}</ListItemText>
                <ListItemText className="req-item"><i>Autore richiesta:</i> {props.user_name}</ListItemText>
                <ListItemText className="req-item"><i>Canzone:</i> {props.reqs_title}</ListItemText>
                <ListItemText className="req-item"><i>Autore Canzone:</i> {props.reqs_author}</ListItemText>
                {props.reqs_tono && <ListItemText className="req-item"><i>Tonalità:</i> {props.reqs_tono}</ListItemText>}
                <ListItemText className="req-item"><i>Tipo richiesta:</i> {props.type_name}</ListItemText>
                {props.reqs_dedication && <ListItemText className="req-item"><i>Dedica:</i> {props.reqs_dedication}</ListItemText>}
                <ListItemSecondaryAction>
                    <IconButton onClick={() => {props.handleClose(props.reqs_id)}}>
                        <Icon icon="material-symbols:done" width={40} color="#EEEEEE" />
                    </IconButton>
                </ListItemSecondaryAction>
            </div>
        </ListItem>

    </>

}