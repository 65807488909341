import React from "react";
import utils from "../../components/utils";
import {Button, Dialog, Grid, Input, Paper, TextField, Typography} from "@mui/material";

function FilesSections(props) {

    const [filesList, setFilesList] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [reload, setReload] = React.useState(true)

    const [fileName, setFileName] = React.useState("")
    const [fileBlob, setFile] = React.useState()

    const updateRefresh = () => {
        setReload(r => {
            return !r
        })
    }

    React.useEffect(() => {
        setIsLoading(true);
        utils.apiGet(`files`, {authorization: "Bearer " + props.admin.token})
            .then(res => {
                setFilesList(res.content)
                setIsLoading(false)
            })
    }, [props.admin.token, reload])

    const handleUpload = () => {
        const body = {
            name: fileName,
            file_blob: fileBlob
        }
        utils.apiPost(`files/add`, body, {authorization: "Bearer " + props.admin.token})
            .then(() => {
                updateRefresh()
                setFileName("")
                setFile()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result.split(',')[1]);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return <>
        <Typography>Gestione Files</Typography>
        <div>
            {!isLoading && <Grid container spacing={2}>
                {filesList.map((e, k) => (<Grid item xs={12} sm={4} md={2}>
                    <Paper style={{width: "150px", height: "150px"}}>
                        <img src={`https://karaoke.movimento.events:3001/files/${e}`}
                             style={{maxWidth: "100px", maxHeight: "100px"}}/>
                        <Typography>{e}</Typography>
                    </Paper>
                </Grid>))}
            </Grid>}
        </div>
        <div>
            <Paper style={{marginTop: "0.4rem"}}>
                <Typography>Nuovo File</Typography>
                <Grid container spacing={2} style={{padding: "0.4rem"}}>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="file"
                            type="file"
                            inputProps={{accept: "image/*"}}
                            onChange={({target})=>{
                                setFileName(target.files[0].name)
                                convertBase64(target.files[0]).then(f=>setFile(f))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="fileName"
                            label="NomeFile"
                            fullWidth
                            required
                            value={fileName}
                            onChange={({target}) => {
                                setFileName(target.value)
                            }} x
                        /></Grid>

                </Grid>
                <Button onClick={handleUpload}>Invia</Button>
            </Paper>
        </div>
    </>

}

export default React.memo(FilesSections)