import React from "react";
import utils from "./utils";
import {LinearProgress, MenuItem, TextField} from "@mui/material";

export default function EventSelector(props) {

    const [isLoading, setIsLoading] = React.useState(false)
    const [list, setList] = React.useState([])

    const host = props.host
    const url = !host ? '/events/today' : `/events/today?host=${host}`

    React.useEffect(() => {
        setIsLoading(true)
        utils.apiGet(url)
            .then(result => {
                setList(result.content)
                setIsLoading(false)
            }).catch(() => {
                setIsLoading(false)
        })
    }, [url])

    return <TextField
        id={props.name}
        value={props.value}
        onChange={props.change}
        select
        {...props}
    >
        {
            isLoading ? <LinearProgress/> :
                list.length === 0 ? <MenuItem disabled>Non ci sono eventi</MenuItem> :
                    list.map((option) => (
                        <MenuItem key={option.event_id} value={option.event_id}>
                            {option.event_title} - {option.event_location}
                        </MenuItem>
                    ))}
    </TextField>

}